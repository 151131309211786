import React, { Component } from "react";
import "./DonateFrom.css";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Elements, StripeProvider } from "react-stripe-elements";
import InjectedCheckoutForm from "../components/CheckoutForm";

const CRYPTO_NAMES = ["Bitcoin", "Ethereum", "Aion"];
const CRYPTO_ADDRESSES = [
  "3PJys7asAsD5WQKhWywxnVTyu8kUMHN6Nm",
  "0x5b8498A57f6F9F17dE40e3332E40D8D128Dd4b86",
  "0xa09c41fae116dd1309d59976aa6e981f8af08fab15aed5337699ddae8047d477"
];
const STRIPE_PK = "pk_live_aSizlI4WF3sb2XWJNA9suuTo"; //"pk_test_0iUJlz4pmd187QcJG7TECBIr00O2WTfTY6"
class DonateForm extends Component {
  state = {
    activeIndex: 0,
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    showCrytpo: false,
    copied: false,
    activeCryptoIndex: 0,
    loading: false,
    error: false,
    successMessage: false,
    buttonDisabled: true
  };

  setActiveIndex = index => {
    this.setState({
      activeIndex: index
    });
  };
  setActiveCryptoIndex = index => {
    this.setState({
      activeCryptoIndex: index
    });
  };
  setField = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSwitchChange = e => {
    this.setState({ showCrytpo: e.target.checked });
  };
  onCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };
  validate = () => {
    const { activeIndex } = this.state;
    this.setState({ loading: true, error: false });
    if (activeIndex === 1) {
      // only petition
      return this.isPetitionValid();
    } else if (activeIndex === 2) {
      //only donation
      return this.isDonationValid();
    } else {
      //both
      return this.isPetitionValid() && this.isDonationValid();
    }
  };
  isPetitionValid = () => {
    const { firstName, lastName, email, country } = this.state;
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      country.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  isDonationValid = () => {
    const { stripeGetter, showCrytpo } = this.state;
    const { donationAmount } = stripeGetter(); //elements
    if (
      donationAmount === 0 ||
      isNaN(donationAmount) //||!cardElement.complete
    ) {
      return false || showCrytpo;
    }
    return true;
  };
  onSubmit = async () => {
    if (!this.validate()) {
      this.setState({
        error: "Please enter all the fields",
        loading: false
      });
      return;
    }
    const {
      activeIndex,
      firstName,
      lastName,
      email,
      country,
      stripeGetter,
      showCrytpo
    } = this.state;
    this.setState({ loading: true, error: false });
    if ((activeIndex === 0 || activeIndex === 2) && !showCrytpo) {
      this.donate(
        stripeGetter(),
        activeIndex === 0 ? { firstName, lastName, email, country } : null
      );
    } else {
      const resp = await fetch(`${process.env.REACT_APP_API}/petition`, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstname: firstName,
          lastname: lastName,
          email: email,
          country: country
        })
      });
      const respJSON = await resp.json();
      console.log(respJSON);
      if (respJSON.error) {
        this.setState({
          error: "Missing some petition data, please enter all the fields",
          loading: false
        });
      } else {
        this.setState({
          error: false,
          loading: false,
          successMessage: "Thank you for signing the petition"
        });
      }
    }
  };
  stripeHandler = async func => {
    this.setState({ stripeGetter: func });
  };
  aknowladgeListener = akn => {
    this.setState({ buttonDisabled: !akn });
  };
  donate = async (data, extra) => {
    const { stripe, elements, donationAmount, anonToggle } = data;
    let extraData = {};
    if (extra) {
      extraData = {
        firstname: extra.firstName,
        lastname: extra.lastName,
        email: extra.email,
        country: extra.country
      };
    }
    const paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("cardNumber")
    });
    console.log(paymentMethod);
    if (paymentMethod.error) {
      this.setState({
        error: "Error during the payment, please check the form fields",
        loading: false
      });
      return;
    }
    const resp = await fetch(`${process.env.REACT_APP_API}/donate`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payment_method_id: paymentMethod.paymentMethod.id,
        ...extraData,
        donationAmount,
        keepAnonymous: anonToggle
      })
    });
    const respJSON = await resp.json();
    console.log(respJSON);
    //respJSON.data.client_secret
    stripe.confirmCardPayment(respJSON.data.client_secret, {
      payment_method: {
        card: elements.getElement("cardNumber")
      }
    });

    const respConf = await fetch(`${process.env.REACT_APP_API}/confirm`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payment_intent_id: respJSON.data.id,
        payment_method: respJSON.payment_method
      })
    });
    const respConfJSON = await respConf.json();
    if (respConfJSON.data.status === "succeeded") {
      this.setState({ paymentLoading: true });
    } else {
      this.setState({ paymentError: true });
    }
    if (respConfJSON.data.status !== "succeeded") {
      //todo rework error handling
      // this.setState({
      //   error: "Missing some payment data, please check the form fields",
      //   loading: false
      // });
      this.setState({
        error: false,
        loading: false,
        successMessage: "Thank you for donating"
      });
    } else {
      this.setState({
        error: false,
        loading: false,
        successMessage: "Thank you for donating"
      });
    }
  };
  render() {
    const {
      activeIndex,
      firstName,
      lastName,
      email,
      country,
      showCrytpo,
      copied,
      activeCryptoIndex,
      loading,
      error,
      successMessage,
      buttonDisabled
    } = this.state;
    return (
      <div key={0} style={{ width: "100%" }} className="donate-form row">
        <div className="col-md-6 petition-section">
          <div className="pl-3 pr-3">
            <div className="common-title">Help Bring Shawki to Canada</div>
            <div className="common-paragraph mt-4">
              Thank you for letting us tell Shawki's story. There are three ways
              you can support us and all three are equally as important.
              <br />
              <br />
              Help us by signing the petition showing your support for our
              campaign. As we navigate the legal process, the amount of support
              we have will be important. Help us by supporting us financially.
              All this will cost money and any help we can get will be
              appreciated. Help us by telling others. Share Shawki's story with
              your network and help us bring Shawki to Canada.
              <br />
              <br />
              All donations will go towards achieving the goals outlined on our
              page. Please don't hesitate to contact us if you have any
              questions or if you'd simply like to know more.
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className="btn-group">
              <button
                type="button"
                className={"btn" + (activeIndex === 0 ? " active" : "")}
                onClick={() => {
                  this.setActiveIndex(0);
                }}
              >
                Sign Petition &amp; Donate
              </button>
              <button
                type="button"
                className={
                  "text-center ml-auto mr-auto btn" +
                  (activeIndex === 1 ? " active" : "")
                }
                onClick={() => {
                  this.setActiveIndex(1);
                }}
              >
                Sign Petition
              </button>
              <button
                type="button"
                className={
                  "text-right btn" + (activeIndex === 2 ? " active" : "")
                }
                onClick={() => {
                  this.setActiveIndex(2);
                }}
              >
                Donate
              </button>
            </div>
            <div className="form-wrapper">
              {activeIndex === 0 || activeIndex === 1 ? (
                <div className="mt-5 mb-5">
                  <div>
                    <input
                      onChange={this.setField}
                      name="firstName"
                      value={firstName}
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                    />
                    <label id="input-lable">First Name</label>
                  </div>
                  <div className="mt-4">
                    <input
                      onChange={this.setField}
                      name="lastName"
                      value={lastName}
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                    />
                    <label id="input-lable">Last Name</label>
                  </div>
                  <div className="mt-4">
                    <input
                      onChange={this.setField}
                      name="email"
                      value={email}
                      type="text"
                      className="form-control"
                      placeholder="Email"
                    />
                    <label id="input-lable">Email</label>
                  </div>
                  <div className="mt-4">
                    <input
                      onChange={this.setField}
                      name="country"
                      value={country}
                      type="text"
                      className="form-control"
                      placeholder="Country"
                    />
                    <label id="input-lable">Country</label>
                  </div>
                </div>
              ) : null}
              {activeIndex === 0 || activeIndex === 2 ? (
                <div className="mt-5 mb-2">
                  <span className="common-title-extra-small  mb-4">
                    Payment Information
                  </span>
                  <br />

                  <div className=" mt-3">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={e => this.handleSwitchChange(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="pl-5">Donate with Crypto</div>
                  {!showCrytpo ? (
                    <div>
                      <div className="mt-4">
                        <StripeProvider apiKey={STRIPE_PK}>
                          <Elements>
                            <InjectedCheckoutForm
                              aknowladgeListener={this.aknowladgeListener}
                              stripeHandler={this.stripeHandler}
                            />
                          </Elements>
                        </StripeProvider>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 crypto-payments row">
                      <div className="col-6">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {CRYPTO_NAMES[activeCryptoIndex]}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              type="button"
                              className={
                                "btn dropdown-item " +
                                (activeCryptoIndex === 0 ? " active" : "")
                              }
                              onClick={() => {
                                this.setActiveCryptoIndex(0);
                              }}
                            >
                              {/* <i className="fab fa-bitcoin"></i> */}
                              Bitcoin
                            </button>
                            <button
                              type="button"
                              className={
                                "btn dropdown-item " +
                                (activeCryptoIndex === 1 ? " active" : "")
                              }
                              onClick={() => {
                                this.setActiveCryptoIndex(1);
                              }}
                            >
                              {/* <i className="fab fa-ethereum"></i> */}
                              Ethereum
                            </button>
                            <button
                              type="button"
                              className={
                                "btn dropdown-item " +
                                (activeCryptoIndex === 2 ? " active" : "")
                              }
                              onClick={() => {
                                this.setActiveCryptoIndex(2);
                              }}
                            >
                              {/* <img
                              style={{ verticalAlign: "initial" }}
                              width="12px"
                              height="12px"
                              alt="aion"
                              src="images/ic_aion.png"
                            ></img> */}
                              Aion
                            </button>
                          </div>
                        </div>

                        <div className="crypto-address mt-5">
                          {CRYPTO_ADDRESSES[activeCryptoIndex]}
                          <CopyToClipboard
                            text={CRYPTO_ADDRESSES[activeCryptoIndex]}
                            onCopy={this.onCopy}
                          >
                            <div>
                              <div className="share-button mt-3">
                                Copy Address
                              </div>
                              <span id="copy-tooltip">
                                {copied ? "Copied" : "Copy"}
                              </span>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="col-6">
                        <QRCode
                          className="mt-3"
                          value={CRYPTO_ADDRESSES[activeCryptoIndex]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <div>
                {error ? (
                  <div
                    className="common-error mt-2"
                    style={{ position: "absolute" }}
                  >
                    {error}
                  </div>
                ) : null}
                {successMessage ? (
                  <div>
                    <img alt="check" src="images/ic_checkmark.svg"></img>

                    <span className="ml-3">{successMessage}</span>
                  </div>
                ) : loading ? (
                  <div className="spinner-border mt-5"></div>
                ) : (
                  <button
                    disabled={buttonDisabled && activeIndex !== 1}
                    type="button"
                    className="btn btn-danger main-button mt-5"
                    onClick={this.onSubmit}
                  >
                    Show Support
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <img
          className="maple-image"
          alt="maple"
          src="images/ic_maple_outline_half.svg"
        ></img>
      </div>
    );
  }
}

export default DonateForm;

import React, { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ShareComponent from "../components/ShareComponent";
import moment from "moment";
import "./Home.css";
import DonateForm from "../components/DonateForm";
import VideoPlayer from "../components/VideoPlayer";
class Home extends Component {
  state = {
    didInit: false,
    playing: false,
    modalOpened: false,
    donations: [],
    total: "",
  };
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }
  async componentDidMount() {
    try {
      const donations = await fetch(`${process.env.REACT_APP_API}/donations`);
      const donationsParsed = await donations.json();
      this.setState({
        donations: donationsParsed.data.lastTen,
        total: Math.round(donationsParsed.data.total)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  playPause = () => {
    const myVideo = this.videoRef.current;
    if (myVideo.paused) {
      myVideo.play();
      this.setState({ playing: true });
    } else {
      myVideo.pause();
      this.setState({ playing: false });
    }
  };
  toggleModal = () => {
    const { modalOpened } = this.state;
    this.setState({ modalOpened: !modalOpened });
  };
  render() {
    const { modalOpened, donations, total } = this.state;
    const modal = modalOpened ? (
      <div key={0} className="modal-container p-5">
        <button className="btn modal-close" onClick={this.toggleModal}>
          <img alt="close" src="images/ic_close.svg" />
        </button>
        <DonateForm />
      </div>
    ) : null;

    const socialComments = [
      {
        link:
          "https://www.linkedin.com/posts/inventxr_dearmrfree-activity-6622050441121411072-gsD1",
        icon: "images/1_social_profile.jpeg",
        text:
          "I have a letter from an extraordinary sixteen year old from Aleppo. He is the Future and we need to collectively defend the future. Shawki Sukkar has a genius I have rarely seen and he will transform the world. I am writing my book #DearMrFree to ensure Shawki's dream is realized and to inspire...",
        from: "Freedom Cheteni",
        platform: "LinkedIn",
      },
      {
        link: "https://twitter.com/insideNiMA/status/1182257502806781953?s=20",
        icon: "images/2_social_profile.jpg",
        text:
          "We will be happy to sponsor @shawki_sukker visa to come work with us in Berlin! Genius",
        from: "@insideNiMA",
        platform: "Twitter",
      },
      {
        link: "https://twitter.com/RobTG4/status/1182626284561526784",
        icon: "images/3_social_profile.jpg",
        text:
          "One of my favorite devcon scholars and source of inspiration - @shawki_sukker",
        from: "@RobTG4",
        platform: "Twitter",
      },
      {
        link: "https://twitter.com/moeghashim/status/1180017584999145477",
        icon: "images/4_social_profile.jpeg",
        text:
          "This is mind-blowing. @shawki_sukker 16yr from Aleppo is going to devcon. Thanks  @ethereum for the sponsorship. The opportunity for Shawki to learn is immersive. I can't believe someone from Aleppo is going to devcon.",
        from: "@moeghashim",
        platform: "Twitter",
      },
      {
        link: "https://twitter.com/0xstark/status/1217094075440140288",
        icon: "images/5_social_profile.png",
        text:
          "👋 hey twitter! Meet Shawki Sukkar (@ShawkiSukkar), a 16yo self-taught developer from Aleppo, Syria. I want you to help him find a job or internship in Canada, so he can move here and continue his career and education in a safe environment",
        from: "@0xstark",
        platform: "Twitter",
      },
    ];

    const donationsLayouts = donations.map((item, index) => (
      <div key={index} className="col-md-4 mt-4">
        <div className="card shadow-lg p-4">
          <div className="d-flex">
            <div className="flex-grow-1">
              <div className="person">
                {item.firstname
                  ? item.firstname + " " + item.lastname
                  : "Anonymous"}
              </div>
              <div className="money mt-3">{item.amount} $</div>
            </div>
            <div className="date">
              {moment(item.timestamp).format("MMM DD")}
            </div>
          </div>
        </div>
      </div>
    ));

    const socialCommentLayouts = socialComments.map((item, index) => (
      <div key={index} className="col-md-6 mt-4 pr-5 social-comment">
        <img
          src={item.icon}
          className="rounded-circle mobile-size-image"
          alt="user"
        />
        <div className="d-flex">
          <div className="m-auto">
            <img
              src={item.icon}
              className="rounded-circle full-size-image"
              alt="user"
            />
          </div>
          <div className="ml-4 comment">{item.text}</div>
        </div>
        <div className="text-right mt-3">
          <span className="from"> - {item.from}</span>
          <span className="platform">
            {" "}
            via <a href={item.link}>{item.platform}</a>
          </span>
        </div>
      </div>
    ));

    return (
      <div key={0}>
        <div className="hero">
          <div className="hero row">
            <div className="col-md-6 text-container-wrapper order-2 order-md-1">
              <div className="d-flex flex-column justify-content-center text-container m-auto">
                <div>
                  <img
                    className="white-maple-image mb-3"
                    alt="maple"
                    src="images/ic_maple_filled.svg"
                  ></img>
                  <br />
                  <span className="common-big-title">
                    BRING
                    <br />
                    SHAWKI
                    <br />
                    TO CANADA
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 img-container-wrapper">
              <img
                className="img-fluid"
                alt="shawki"
                src="images/shawki_picture.png"
              ></img>
            </div>
          </div>
        </div>
        <div className="story-section row" style={{ backgroundColor: "white" }}>
          <div className="col-md-8">
            <div className="common-title">Bring Shawki to Canada</div>

            <p className="common-paragraph mt-5">
              Meet Shawki Sukkar, a 16-year-old living in Aleppo, Syria who
              wants to come to Canada. He’s an incredibly intelligent and
              ambitious young man who we want to help. His personal experience
              living in an incredibly difficult environment has motivated him to
              learn how new technologies can be used to positively impact young
              people like him around the world. Shawki caught our attention with
              his enthusiastic social media outreach to business and technology
              leaders in Toronto. After spending some time getting to know
              Shawki, we’re excited to launch this campaign to bring him to
              Canada.
              <br />
              <br />
              As most of you know, the political environment in Syria is
              complex, let alone the fact that Shawki is still a minor. That
              said, we’re confident that there’s a solution. With your support,
              we’re organizing a team of advocates, immigration experts, and
              industry sponsors to ensure that Shawki can start anew life in
              Canada. Canada would be lucky to have this young man pursue his
              passions and ambitions here.
            </p>
            <br />
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://medium.com/ethereum-foundation-devcon-scholars/the-story-of-shawki-0-1-and-what-is-coming-in-version-0-2-b5445285e926"
            >
              <button type="button" className="btn btn-danger main-button">
                Shawki's Story
              </button>
            </a>
            <br />
            <br />
            <br />
            <ShareComponent url={window.location.href} />
          </div>
          <img
            className="maple-image col-md-4"
            alt="maple"
            src="images/ic_maple_outline_half.svg"
          ></img>
        </div>
        <div className="">
          <div className="story-section row">
            <div className="col-md-7 order-2 order-md-1">
              <p className="common-paragraph">
                We’re raising funds to cover expenses related to Shawki’s
                campaign. All money raised will exclusively be used to help fund
                legal, immigration, travel, and living expenses related to
                Shawki’s move to Canada. We’d love your support!
              </p>
              <div className="section-item">
                <button className="btn" onClick={this.toggleModal}>
                  Donate &amp; Sign a Petition
                  <img src="/images/ic_right_arrow.svg" alt="arrow" />
                </button>
              </div>
            </div>
            <div className="col-md-5 total-container order-1 order-md-2 mb-5">
              <span className="common-number-large  mt-md-5">{total}$</span>
              <br />
              <br />
              <span className="common-subtitle">
                donated as of {moment().format("MMM DD, YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="story-section">
          <div className="common-title">Recent Donations</div>
          <div className="row mt-5">{donationsLayouts}</div>
        </div>
        <div className="story-section">
          <div className="common-title">People are noticing...</div>
          <div className="row mt-5 mb-5">{socialCommentLayouts}</div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-danger main-button mt-5"
              onClick={this.toggleModal}
            >
              Show Support
            </button>
          </div>
        </div>
        <div className="story-section video-section">
          <VideoPlayer
            videoFile="videos/sample_video2.mp4"
            videoPreviewFile="images/video_poster2.png"
          />
          <div className="video-divider">
            <div className="video-circle"></div>
            <div className="video-circle"></div>
            <div className="video-circle"></div>
            <div className="video-circle"></div>
          </div>
          <VideoPlayer
            videoFile="videos/sample_video.mp4"
            videoPreviewFile="images/video_poster.png"
          />
        </div>
        <div className="story-section">
          <div className="common-title">Reached Goals &amp; Updates</div>
          <div className="row mt-5">
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Successfully launched the campaign and started fundraising.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Started student visa application to get Shawki to Canada to
                  complete his high school education.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Many companies and organizations have committed to supporting
                  Shawki's personal &amp; professional development when he
                  arrives in Canada.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Udacity scholarship for online Nanodegree
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Accepted to Canadian High School in Toronto with partial
                  scholarship.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card shadow p-4 card-goal">
                <div>
                  <img alt="check" src="images/ic_checkmark.svg"></img>
                </div>
                <div className="common-paragraph mt-4">
                  Received support from various Members of Parliament to help
                  facilitate Shawki's visa application.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="goal-funds-section">
          <div className="row mb-5">
            <div className="col-md-6 story-section red-container">
              <div className="common-title">Our Goal</div>
              <div className="common-paragraph mt-4">
                Shawki is 16 years old and coming to Canada is only the first
                step in helping him build a new life to pursue his ambitions.
                Although this is likely the most complex part of our campaign,
                there are other ways we want to support Shawki to set him up for
                success.
              </div>
              <div className="common-title-small mt-5">So what’s our goal?</div>
              <div className="common-paragraph mt-4">
                We want Shawki to be safe, supported and empowered as he becomes
                an adult.
              </div>
              <div className="common-title-small mt-5 mb-4">
                We’ll do this by focusing on the following things:
              </div>
              <ul className="mt-3">
                <li>Support Shawki in his immigration process</li>
                <li>
                  Find Shawki a family that will take him in until he turns 18
                </li>
                <li>Cover his costs to move to Canada and get established</li>
                <li>
                  Get Shawki’s education recognized as Ontario high school
                  equivalent, or support him in completing necessary courses to
                  achieve this
                </li>
                <li>
                  Provide Shawki with access to online learning materials to
                  improve his skills as a young software developer
                </li>
                <li>
                  Find Shawki an internship opportunity at a software company in
                  Toronto
                </li>
              </ul>
              <button
                type="button"
                className="btn btn-light main-button-inverse mt-5"
                onClick={this.toggleModal}
              >
                Support Shawki
              </button>
            </div>
            <div className="col-md-6 story-section white-container">
              <div className="common-title">Use of Funds</div>
              <div className="common-paragraph mt-4">
                All the money raised in this campaign will be used directly to
                support Shawki.We commit to only raise the money necessary to
                get us through the listed goals and return all funds that go
                unspent.
                <br />
                <br />
                Although we do not have a detailed breakdown of expenses yet, we
                expect to incur costs in the following areas:
              </div>
              <ul>
                <li>Legal Expenses</li>
                <li>Immigration Fees</li>
                <li>Online Education Fees</li>
                <li>Travel costs</li>
                <li>Temporary Housing Costs</li>
              </ul>
              <div className="common-paragraph mt-4">
                These funds will be managed on Shawki’s behalf by{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/matthewspoke/"
                >
                  Matthew Spoke
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/linaismail/"
                >
                  Lina Ismail
                </a>
                , two of the supporters of this campaign.
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <ShareComponent url={window.location.href} />
            <div className="m-5">
              <span className="common-paragraph">
                Want to help us in another way,{" "}
                <a
                  className="common-link"
                  href="mailto:hello@bringshawkitocanada.com"
                >
                  contact us!
                </a>
              </span>
            </div>
          </div>
        </div>

        <div>
          <ReactCSSTransitionGroup
            transitionName="slide"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionEnter={true}
            transitionLeave={true}
          >
            {modal}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

export default Home;

import React, { Component } from "react";
import PropTypes from "prop-types";

export default class VideoPlayer extends Component {
  static propTypes = {
    videoFile: PropTypes.string.isRequired,
    videoPreviewFile: PropTypes.string.isRequired,
  };

  state = {
    playing: false,
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  playPause = () => {
    const myVideo = this.videoRef.current;
    if (myVideo.paused) {
      myVideo.play();
      this.setState({ playing: true });
    } else {
      myVideo.pause();
      this.setState({ playing: false });
    }
  };
  render() {
    const { playing } = this.state;
    const { videoFile, videoPreviewFile } = this.props;
    return (
      <>
        <div className="video-container">
          <div
            className={
              "play-button-container" + (playing ? " playing-fade" : "")
            }
          >
            <button
              className="play-button"
              id="vidbutton"
              onClick={this.playPause}
            >
              <img
                alt="play"
                src={playing ? "images/ic_pause.svg" : "images/ic_play.svg"}
              />
            </button>
          </div>
          <video
            ref={this.videoRef}
            className="embed-responsive-item"
            poster={videoPreviewFile}
          >
            <source src={videoFile} type="video/mp4"></source>
          </video>
        </div>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-danger main-button help-button"
            onClick={this.toggleModal}
          >
            Help Shawki
          </button>
        </div>
      </>
    );
  }
}

import React from "react";
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement
} from "react-stripe-elements";

class CardSection extends React.Component {
  createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: "#495057",
          fontFamily: '"Muli"',
          fontWeight: "400",
          letterSpacing: "0.64px",
          "::placeholder": {
            color: "rgba(42, 42, 42, 1)",
            fontFamily: '"Muli"',
            fontWeight: "300",
            fontSize: "16px"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      },
      options: {
        fonts: [
          {
            src:
              "https://fonts.googleapis.com/css?family=Muli:900,700,400,600,200,100,300,800",
            family: 'Muli"',
            style: "normal"
          }
        ]
      }
    };
  };
  render() {
    return (
      <div>
        <CardNumberElement
          {...this.createOptions()}
          onChange={this.props.onChange}
        />
        <div className="mt-4">
          <div className="d-flex">
            <div className="flex-fill mr-3">
              <CardExpiryElement {...this.createOptions()} />
            </div>
            <div className="flex-fill ml-3">
              <CardCVCElement {...this.createOptions()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardSection;

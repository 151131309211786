// CheckoutForm.js
import React from "react";
import { injectStripe } from "react-stripe-elements";

import CardSection from "./CardSection";

class CheckoutForm extends React.Component {
  state = {
    donationAmount: false,
    anonToggle: false,
    aknowladge: false
  };
  constructor(props) {
    super(props);
    if (props.stripeHandler) {
      props.stripeHandler(this.handleSubmit);
    }
  }

  setField = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = ev => {
    console.log({
      donationAmount: this.state.donationAmount,
      anonToggle: this.state.anonToggle
    });
    return {
      stripe: this.props.stripe,
      elements: this.props.elements,
      cardNumber: this.state.cardNumber,
      donationAmount: this.state.donationAmount,
      anonToggle: this.state.anonToggle
    };
  };
  stripeElementChange = (element, name) => {
    console.log({ element, name });
    if (!element.empty && element.complete) {
      this.setState({ [name]: true });
    }
  };

  render() {
    const { donationAmount, anonToggle, aknowladge } = this.state;
    const { aknowladgeListener } = this.props;
    return (
      <div>
        <div className="mt-3">
          <input
            onChange={this.setField}
            name="donationAmount"
            value={donationAmount}
            type="number"
            className="form-control"
            placeholder="Donation Amount"
          />
          <label id="input-lable" htmlFor="usr">
            Donation Amount
          </label>
        </div>
        <div className="mt-4">
          <CardSection
            onChange={element =>
              this.stripeElementChange(element, "cardNumber")
            }
          />
        </div>
        <div className="mt-4 common-checkbox">
          <button
            onClick={() =>
              this.setState({
                anonToggle: !this.state.anonToggle
              })
            }
          >
            {anonToggle ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas"></i>
            )}
          </button>
          <span className="ml-3">Keep me anonymous</span>
        </div>
        <div className="mt-4 common-checkbox">
          <button
            onClick={() => {
              if (aknowladgeListener) {
                aknowladgeListener(!this.state.aknowladge);
              }
              this.setState({
                aknowladge: !this.state.aknowladge
              });
            }}
          >
            {aknowladge ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas"></i>
            )}
          </button>
          <span className="ml-3">
            I agree that donations made to the campaign is, under law, generally
            considered to be personal gift and is likely not tax-deductible. A
            tax receipt will not be issued.
          </span>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);

import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
class ShareComponent extends React.Component {
  state = { shareOpen: false };
  render() {
    const { url } = this.props;
    const { shareOpen } = this.state;
    return (
      <div>
        <button
          type="button"
          className="share-button"
          onClick={() => {
            this.setState({ shareOpen: !shareOpen });
          }}
        >
          <img id="share-icon" alt="share" src="images/ic_share.svg" />
          <img
            id="share-icon-dark"
            alt="share"
            src="images/ic_share_dark.svg"
          />{" "}
          <span>Share the Story</span>
          {shareOpen && (
            <div className="share-container">
              <div className="shadow share-box">
                <div className="share-box-arrow"></div>
                <div className="d-flex">
                  Share:
                  <FacebookShareButton
                    url={url}
                    quote={"Help us bring Shawki to Canada!"}
                    className="Demo__some-network__share-button"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={url}
                    quote={"Help us bring Shawki to Canada!"}
                    className="Demo__some-network__share-button"
                  >
                    <i className="fab fa-twitter"></i>
                  </TwitterShareButton>
                </div>
                <br />
                <span className="address">{url}</span>
                <CopyToClipboard text={url}>
                  <div className="share-button mt-2">Copy Link</div>
                </CopyToClipboard>
              </div>
            </div>
          )}
        </button>
      </div>
    );
  }
}

export default ShareComponent;

import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./loader.js";
import "./App.css";
import Home from "./pages/Home";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-app">
          <ReactCSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Switch>
              <Route
                exact
                path="/"
                render={props => <Home {...props} content={{ todo: "todo" }} />}
              />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </ReactCSSTransitionGroup>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
